import axios from '@/plugins/axios'

export default {

  send: function (params) {
    try {
      let response = axios({
        method: 'post',
        url: '/api/contact/send',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: params
      })
      console.log("error")
      return response.data || {}
    } catch {
      return {}
    }
  }

}
