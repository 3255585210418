import Vue from 'vue'
import VueRouter from 'vue-router'
import VueAnalytics from 'vue-analytics'

import * as admin from './admin'
import * as home from './home'
import * as documentation from './documentation'
import * as solution from './solution'
import * as portfolio from './portfolio'
import * as blog from './blog'
import * as about from './about'
import * as contact from './contact'

Vue.use(VueRouter)

const routes = [
  ...admin.routes,
  ...home.routes,
  ...documentation.routes,
  ...solution.routes,
  ...portfolio.routes,
  ...blog.routes,
  ...about.routes,
  ...contact.routes,
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "view-home" */ '@/views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueAnalytics, {
  id: 'UA-90758014-1',
  router
})

export default router
