export const routes = [
  {
    path: '/portfolio',
    name: 'Projects',
    component: () => import ('@/views/portfolio/page-list.vue')
  },
  {
    path: '/portfolio-items/page/:page',
    name: 'ProjectsPortfolio',
    props: true,
    component: () => import ('@/views/portfolio/page-list.vue')
  },
  {
    path: '/portfolio-items',
    redirect: '/portfolio-items/page/1'
  },
  {
    path: '/portfolio-items/*',
    redirect: '/portfolio-items/page/1'
  },
  {
    path: '/portfolio/:id',
    name: 'PortfolioContent',
    props: true,
    component: () => import ('@/views/portfolio/page-content.vue')
  }
]
