export const routes = [
  {
    path: '/solution',
    name: 'Solution',
    component: () => import (/* webpackChunkName: "view-solution-list" */ '@/views/solution/page-list.vue')
  },
  {
    path: '/solution/:url',
    name: 'SolutionContent',
    props: true,
    component: () => import (/* webpackChunkName: "view-solution-content" */ '@/views/solution/page-content.vue')
  }
]
