export default {
  state: {
    categories: [],
    sectors: [],
    industries: [],
    clients: []
  },
  mutations: {
    SET_PORTFOLIO_CATEGORIES (state, categories) {
      state.categories = categories;
    },
    SET_PORTFOLIO_SECTORS (state, sectors) {
      state.sectors = sectors;
    },
    SET_PORTFOLIO_INDUSTRIES (state, industries) {
      state.industries = industries;
    },
    SET_PORTFOLIO_CLIENTS (state, clients) {
      state.clients = clients;
    },
  },
  actions: {
  },
  getters: {
  }
}
