import axios from 'axios'
import store from '@/store'

const instance = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  }
});

instance.interceptors.response.use(
  function (response) {
    return response
  }, function (error) {
    console.log(JSON.stringify(error))
    if (error.response.status == 403) {
      store.commit('SET_SESSION_LOGIN_ERROR', 'Session expired')
      store.dispatch('admin-session-logout')
    }
    return Promise.reject(error)
  }
);

export default instance
