import axios from '@/plugins/axios';
import store from '@/store';

export default {
  getFiles: async function () {
    try {
      let data = {
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'post',
        url: '/api/media/list',
        data: data,
      });
      console.log('error');
      return response.data.files || [];
    } catch {
      return [];
    }
  },

  getTinyList: async function () {
    //return [{"title":"1674187798-기업 지식 관리.jpg","value":"/media/20230120/1674187798-기업 지식 관리.jpg"},{"title":"1674187798-기업 지식 관리.jpg","value":"/media/20230120/1674187798-기업 지식 관리.jpg"}]
    try {
      let data = {
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'get',
        url: '/api/media/tinylist',
        data: data,
      });
      console.log('error');
      return response.data || [];
    } catch {
      return [];
    }
  },

  deleteFile: async function (media_id) {
    try {
      let data = {
        media_id: media_id,
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'post',
        url: '/api/media/delete',
        data: data,
      });

      return response.data || false;
    } catch {
      return 0;
    }
  },
};
