export default {
  state: {
    theme: 'light',
    currentPage: 'home'
  },
  mutations: {
    SET_NAV_THEME (state, theme) {
      state.theme = theme;
    },
    SET_NAV_PAGE (state, page) {
      state.currentPage = page;
    }
  },
  actions: {
  },
  getters: {
  }
}
