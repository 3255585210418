import axios from '@/plugins/axios'
import store from '@/store'

export default {

  getClients: async function () {
    try {
      let data = {
        token: store.state.admin.session.token
      }
      let response = await axios({
        method: 'post',
        url: '/api/portfolio/clients',
        data: data
      })
      console.log("error")
      return response.data.clients || []
    } catch {
      return []
    }
  },

  getClient: async function (client_id) {
    try {
      let data = {
        client_id: client_id,
        token: store.state.admin.session.token
      }
      let response = await axios({
        method: 'post',
        url: '/api/portfolio/info',
        data: data
      })
      return response.data.client || {}
    } catch {
      return {}
    }
  },

  getClientCategories: async function () {
    try {
      let data = {
        token: store.state.admin.session.token
      }
      let response = await axios({
        method: 'post',
        url: '/api/portfolio/categories',
        data: data
      })
      return response.data.categories || []
    } catch {
      return []
    }
  },

  getClientSectors: async function () {
    try {
      let data = {
        token: store.state.admin.session.token
      }
      let response = await axios({
        method: 'post',
        url: '/api/portfolio/sectors',
        data: data
      })
      return response.data.sectors || []
    } catch {
      return []
    }
  },

  getClientIndustries: async function () {
    try {
      let data = {
        token: store.state.admin.session.token
      }
      let response = await axios({
        method: 'post',
        url: '/api/portfolio/industries',
        data: data
      })
      return response.data.industries || []
    } catch {
      return []
    }
  },

  saveClient: async function (client) {
    try {
      let data = {
        client: client,
        token: store.state.admin.session.token
      }
      let response = await axios({
        method: 'post',
        url: '/api/portfolio/save_client',
        data: data
      })
      return response.data.client_id || 0
    } catch {
      return 0
    }
  },

  setClientActive: async function (client_id, active) {
    try {
      let data = {
        client_id: client_id,
        active: active,
        token: store.state.admin.session.token
      }
      let response = await axios({
        method: 'post',
        url: '/api/portfolio/toggle_client',
        data: data
      })
      return response.data || false
    } catch {
      return 0
    }
  },

  saveCategory: async function (category) {
    try {
      let data = {
        category: category,
        token: store.state.admin.session.token
      }
      let response = await axios({
        method: 'post',
        url: '/api/portfolio/save_category',
        data: data
      })
      return response.data.category_id || 0
    } catch {
      return 0
    }
  },
  saveSector: async function (sector) {
    try {
      let data = {
        sector: sector,
        token: store.state.admin.session.token
      }
      let response = await axios({
        method: 'post',
        url: '/api/portfolio/save_sector',
        data: data
      })
      return response.data.sector_id || 0
    } catch {
      return 0
    }
  },
  saveIndustry: async function (industry) {
    try {
      let data = {
        industry: industry,
        token: store.state.admin.session.token
      }
      let response = await axios({
        method: 'post',
        url: '/api/portfolio/save_industry',
        data: data
      })
      return response.data.industry_id || 0
    } catch {
      return 0
    }
  }

}
