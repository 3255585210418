import store from '@/store';
import axios from '@/plugins/axios';

export default {
  getBlogCategories: async function () {
    try {
      store.commit('SET_BLOG_CATEGORIES', []);
      let response = await axios({
        method: 'post',
        url: '/api/blogs/categories',
      });
      store.commit(
        'SET_BLOG_CATEGORIES',
        response.data.categories || []
      );
    } catch {
      console.log('error fetching categories');
    }
  },

  getBlogs: async function (search = '') {
    try {
      store.commit('SET_BLOG_LIST', []);
      let data = {
        search: search,
      };
      let response = await axios({
        method: 'post',
        url: '/api/blogs/list',
        data: data,
      });
      var blogs = response.data.blogs || [];
      store.commit('SET_BLOG_LIST', blogs);
      store.commit('SET_BLOG_TAGS', blogs);
    } catch {
      console.log('error fetching blogs');
    }
  },

  getPopularBlogs: async function () {
    try {
      store.commit('SET_BLOG_POPULAR', []);
      let response = await axios({
        method: 'post',
        url: '/api/blogs/popular',
      });
      store.commit('SET_BLOG_POPULAR', response.data.blogs || []);
    } catch {
      console.log('error fetching blogs');
    }
  },

  getBlog: async function (blog_url) {
    try {
      let data = {
        blog_url: blog_url,
      };
      let response = await axios({
        method: 'post',
        url: '/api/blogs/view',
        data: data,
      });
      return response.data.blog || {};
    } catch {
      return {};
    }
  },
};
