export default {
  state: {
    solutions: []
  },
  mutations: {
    SET_SOLUTION_SOLUTIONS (state, solutions) {
      state.solutions = solutions;
    },
  },
  actions: {
  },
  getters: {
  }
}
