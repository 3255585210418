<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'App',
  beforeCreate () {
    api.documentation.getDocServices()
    api.solution.getSolutions()
  }
}
</script>

<style>
:root {
  --animate-delay: .25s;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}
body {
  background-color: white;
  position: relative;
}

div#app {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container-fixed {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
