export const routes = [
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => import(/* webpackChunkName: "view-admin-login" */ '@/views/admin/Login.vue')
  },
  {
    path: '/admin',
    redirect: '/admin/dashboard',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "view-admin" */ '@/views/admin/Page.vue'),
    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "view-admin-dashboard" */ '@/views/admin/Dashboard.vue')
      },
      {
        path: 'media',
        redirect: '/admin/media/list',
        component: () => import(/* webpackChunkName: "view-admin-media" */ '@/views/admin/media/Page.vue'),
        children: [
          {
            path: 'list',
            component: () => import(/* webpackChunkName: "view-admin-media-list" */ '@/views/admin/media/List.vue')
          },
          {
            path: 'upload',
            component: () => import(/* webpackChunkName: "view-admin-media-upload" */ '@/views/admin/media/Upload.vue')
          }
        ]
      },
      {
        path: 'blogs',
        redirect: '/admin/blogs/list',
        component: () => import(/* webpackChunkName: "view-admin-blogs" */ '@/views/admin/blogs/Page.vue'),
        children: [
          {
            path: 'list',
            component: () => import(/* webpackChunkName: "view-admin-blogs-list" */ '@/views/admin/blogs/List.vue')
          },
          {
            path: 'views',
            component: () => import(/* webpackChunkName: "view-admin-blogs-list" */ '@/views/admin/blogs/MonthlyViews.vue')
          },
          {
            path: 'hidden',
            component: () => import(/* webpackChunkName: "view-admin-blogs-hidden" */ '@/views/admin/blogs/Hidden.vue')
          },
          {
            path: 'add',
            redirect: '/admin/blogs/edit/0',
          },
          {
            path: 'edit/:id',
            props: true,
            component: () => import(/* webpackChunkName: "view-admin-blogs-edit" */ '@/views/admin/blogs/Edit.vue')
          }
        ]
      },
      {
        path: 'portfolio',
        redirect: '/admin/portfolio/clients',
        component: () => import(/* webpackChunkName: "view-admin-blogs" */ '@/views/admin/portfolio/Page.vue'),
        children: [
          {
            path: 'clients',
            component: () => import(/* webpackChunkName: "view-admin-blogs-list" */ '@/views/admin/portfolio/Clients.vue')
          },
          {
            path: 'categories',
            component: () => import(/* webpackChunkName: "view-admin-blogs-list" */ '@/views/admin/portfolio/Categories.vue')
          },
          {
            path: 'sectors',
            component: () => import(/* webpackChunkName: "view-admin-blogs-list" */ '@/views/admin/portfolio/Sectors.vue')
          },
          {
            path: 'industries',
            component: () => import(/* webpackChunkName: "view-admin-blogs-list" */ '@/views/admin/portfolio/Industries.vue')
          },
          {
            path: 'add',
            redirect: '/admin/portfolio/edit/0',
          },
          {
            path: 'edit/:id',
            props: true,
            component: () => import(/* webpackChunkName: "view-admin-blogs-edit" */ '@/views/admin/portfolio/Edit.vue')
          }
        ]
      }
    ]
  }
]
