export default {
  state: {
    text: {
      more: 'DISCOVER MORE',
      back: 'BACK TO THE TOP'
    },
    light: {
      color: '#FFFFFF',
      arrow: {
        up: '/assets/icons/arrow-up.png',
        down: '/assets/icons/arrow-down.png'
      },
      scroll: {
        up: '/assets/icons/mouse-up-gray.png',
        mid: '/assets/icons/mouse-mid-gray.png',
        down: '/assets/icons/mouse-down-gray.png'
      }
    },
    dark: {
      color: '#959595',
      arrow: {
        up: '/assets/icons/arrow-up-gray.png',
        down: '/assets/icons/arrow-down-gray.png'
      },
      scroll: {
        up: '/assets/icons/mouse-up-gray.png',
        mid: '/assets/icons/mouse-mid-gray.png',
        down: '/assets/icons/mouse-down-gray.png'
      }
    },
    theme: 'light',
    pages: [],
    currentIndex: 0,
    hasScroll: true
  },
  mutations: {
    SET_MORE_THEME (state, theme) {
      state.theme = theme;
    },
    SET_MORE_PAGES (state, pages) {
      state.currentIndex = 0;
      state.pages = pages;
    },
    SET_MORE_CURRENT_INDEX (state, index) {
      state.currentIndex = index;
    },
    SET_MORE_NEXT_PAGE (state) {
      state.currentIndex++;
    },
    SET_MORE_HAS_SCROLL (state, hasScroll) {
      state.hasScroll = hasScroll;
    }
  },
  actions: {
  },
  getters: {
    moreHasNextPage: (state) => () => {
      return state.currentIndex < state.pages.length - 1
    }
  }
}
